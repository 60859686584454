/*! GS1 Web Guidelines */
// ============================================================================
@use "theme/variables" as gs1-vars;
@use "../node_modules/rfs/scss" as rfs with ($rfs-base-value: gs1-vars.$rfs-base-value,
  $rfs-factor: gs1-vars.$rfs-factor,
  $rfs-breakpoint: gs1-vars.$rfs-breakpoint);
@use "theme/colors";
@use "theme/normalize-custom";
@use "theme/type";
@use "theme/utilities";
@use "theme/buttons";
@use "theme/headerFooter";

/* You can add global styles to this file, and also import other style files */
//@import 'primeflex/primeflex.scss';
//@import "primeng/resources/themes/lara-light-blue/theme.css";
//@import "primeng/resources/primeng.css";
//@import "bootstrap-icons/font/bootstrap-icons.css";

html {
  width: 100%;
  overflow-x: hidden;
}
